export enum EditorView {
	Split,
	Blocks,
	Code,
	Preview,
	Serial
}

export interface EditorButton {
	text: string;
	color: string;
	icon?: string;
	faIcon?: string;
	tooltip?: string;
	visible?: boolean;
	action: VoidFunction;
}

export enum EditorButtons {
	Run,
	Save,
	DownloadHex,
	Share,
	Popout,
	DownloadPython,
	RunTerminalCode,
	Connect,
	ConnectPico,
	RunPicoCode
}

export interface EditorTab {
	title: string;
	action: Function | void;
	active: EditorView;
	tooltip?: string;
}

export interface ShowcaseProjectForm {
	title: string;
	description: string;
	image: string;
}

export interface ApprovedExtension {
	title: string;
	subtitle: string;
	url: string;
	image: string;
}