import { ref, Ref } from "vue";

interface ExtensionConfig {
	library: string;
}

export const activeExtensions: Ref<Array<string>> = ref([]);

export async function getDefinitions(url: string): Promise<string> {
	let file: string = "";
	const repo: Array<string> = url.split("/");
	await fetch(`https://api.github.com/repos/${repo[3]}/${repo[4]}/contents/definitions.ts`, {
		headers: {
			"accept": "application/vnd.github.VERSION.raw"
		}
	}).then(async (response: Response) => {
		file = await response.text();
	});
	return file;
}

export async function getGenerators(url: string): Promise<string> {
	let file: string = "";
	const repo: Array<string> = url.split("/");
	await fetch(`https://api.github.com/repos/${repo[3]}/${repo[4]}/contents/generators.ts`, {
		headers: {
			"accept": "application/vnd.github.VERSION.raw"
		}
	}).then(async (response: Response) => {
		file = await response.text();
	});
	return file;
}

export async function getToolbox(url: string): Promise<string> {
	let file: string = "";

	const repo: Array<string> = url.split("/");
	await fetch(`https://api.github.com/repos/${repo[3]}/${repo[4]}/contents/toolbox.xml`, {
		headers: {
			"accept": "application/vnd.github.VERSION.raw"
		}
	}).then(async (response: Response) => {
		file = await response.text();
	});
	return file;
}

export async function getConfig(url: string): Promise<ExtensionConfig> {
	let config: ExtensionConfig = { library: "" };

	const repo: Array<string> = url.split("/");
	await fetch(`https://api.github.com/repos/${repo[3]}/${repo[4]}/contents/config.json`, {
		headers: {
			"accept": "application/vnd.github.VERSION.raw"
		}
	}).then(async (response: Response) => {
		config = await response.json() as ExtensionConfig;
	});
	
	return config;
}

export async function getCodeFile(url: string): Promise<string> {
	const config: ExtensionConfig = await getConfig(url);
	let file: string = "";

	const repo: Array<string> = url.split("/");
	await fetch(`https://api.github.com/repos/${repo[3]}/${repo[4]}/contents/${config.library}`, {
		headers: {
			"accept": "application/vnd.github.VERSION.raw"
		}
	}).then(async (response: Response) => {
		file = await response.text();
	});

	return file;
}