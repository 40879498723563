import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const microbit: Platform = {
	name: "BBC micro:bit",
	key: "microbit",
	image: "/legacy/assets/images/platforms/microbit.png",
	icon: "/legacy/assets/images/platforms/microbit-icon.svg",
	color: "bg-gradient-to-b from-green-300 to-cyan-500",
	fileExtension: "py",
	actions: [EditorButtons.Save, EditorButtons.Connect, EditorButtons.DownloadHex],
	extensionsActive: true
};