import { authentication, FirebaseProviders } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { LoginState } from "./login-state";

export class LoginModel {
	// State for Login View
	public state: LoginState = new LoginState();

	/**
	 * Get Translated text for the login view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("login", key);
	}

	public login(): void {
		authentication.signInUserWithEmailAndPassword(this.state.email, this.state.password);
	}

	public register(): void {
		authentication.registerUserWithEmailAndPassword(this.state.email, this.state.password);
	}

	public resetPassword(): void {
		authentication.sendResetPasswordEmail(this.state.email);
	}
	
	public socialLogin(provider: FirebaseProviders): void {
		authentication.signInUserWithProvider(provider);
	}
} 