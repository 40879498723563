/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { getTranslatedText } from "@/data/providers/localization-provider";
import { HomeState } from "./home-state";
import { reactive, toRaw } from "vue";
import { FirebaseFile, getAllProjectsAsync, getLocalDataForFile } from "@/data/providers/files-provider";
import { code, xmlCode } from "../editor/editor-state";
import { state } from "@/data/providers/global-provider";
import router from "@/router";
import { Platform } from "@/data/providers/platform-provider";
import { authentication } from "@/data/providers/authentication-provider";
import { ShowcaseProject } from "../showcase/showcase-types";
import { appInsights } from "@/main";
import { activeExtensions } from "@/data/providers/extensions-provider";

export class HomeModel {
	// State for home View
	public state: HomeState = reactive(new HomeState());
		
	/**
	 * Get Translated text for the home view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("home", key);
	}

	/**
	 * Open a project in the editor
	 */
	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((text: string) => {
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				appInsights?.trackEvent({
					name: `Load ${state.platform?.name as string} Mode`
				});
				state.isFirebaseProject = true;
				state.currentProject = project.ref;
				const codeSplit: Array<string> = text.split(/\r?\n/);
				if (codeSplit[codeSplit.length-2] && codeSplit[codeSplit.length-2].includes("[")) {
					activeExtensions.value = JSON.parse(codeSplit[codeSplit.length-2]);
				}
				if (project.label.split(`(${state.platform?.key})`)[1] === state.platform?.fileExtension) {
					state.textOnlyMode = true;
					code.value = text;
				}
				else {
					xmlCode.value = text;
				}
				router.push({path: "/editor"});
			});
		});
	}
	
	/**
	 * Get all projects from firebase and push to view state
	 */
	public getAllFilesFromFirebase(): void {
		this.state.isBusy = true;
		getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
			if (files?.items) {
				if (files?.items.length > 0) {
					files.items.forEach((ref: firebase.default.storage.Reference) => {
						ref.getDownloadURL().then((url: string) => {
							ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
								this.state.projects.push({label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta, date: meta.updated});
								this.state.projects.sort((a: FirebaseFile, b: FirebaseFile) => {
									return new Date(a.date).getTime() - new Date(b.date).getTime();
								}).reverse();
								this.state.isBusy = false;
							});
						});
					});
				}
				else {
					this.state.isBusy = false;
				}
			}
		});
	}

	/**
	 * Open editor with specified mode
	 */
	public openEditorWithPlatform(platform: Platform): void {
		state.platform = platform;
		appInsights?.trackEvent({
			name: `Load ${state.platform?.name} Mode`
		});
		router.push({path: "/editor"});
	}

	public getLatestShowcaseProjects(): void {
		this.state.latestShowcaseProjectsBusy = true;
		authentication.db.collection("showcase").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					if (project.platform) {
						project.id = snapshot.id;
						this.state.latestShowcaseProjects.push(project);
					}
				}
				this.state.latestShowcaseProjectsBusy = false;
			});
		});
	}
}