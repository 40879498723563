
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { DropdownOption } from "./eb-thumbnail-card-types";

export default defineComponent({
	name: ComponentName.EbThumbnailCard,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		image: {
			type: String
		},
		badge: {
			type: String
		},
		visible: {
			type: Boolean,
			default: true
		},
		gradientImage: {
			type: String
		},
		dropdownOptions: {
			type: Object as () => DropdownOption
		},
		gradient: {
			type: String
		},
		fixed: {
			type: Boolean,
			default: false
		},
	},
	emits: ["cardClick"],
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		closeDropdown() {
			this.isOpen = false;
		}
	}
});
