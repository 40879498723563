<template>
	<div class="flex items-center justify-between">
		<span class="flex-grow flex flex-col">
			<span
				v-if="title"
				class="text-sm font-medium text-gray-900"
			>
				{{ title }}
			</span>
			<span
				v-if="subtitle"
				class="text-sm text-gray-500"
			>
				{{ subtitle }}
			</span>
		</span>
		<button
			class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			:class="component.toggled.value ? 'bg-blue-500' : 'bg-gray-200'"
			@click="toggleSwitch()"
		>
			<span
				:class="component.toggled.value ? 'translate-x-5' : 'translate-x-0'"
				class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
			/>
		</button>
	</div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import { ComponentName } from "../constants";
import { Data } from "../types";
import { EbToggle } from "./eb-toggle";

export default defineComponent({
	name: ComponentName.EbToggle,
	props: {
		title: {
			type: String,
			default: ""
		},
		subtitle: {
			type: String,
			default: ""
		},
		toggle: {
			type: Boolean,
			default: false
		}
	},
	emits: ["toggled", "untoggled"],
	// eslint-disable-next-line @typescript-eslint/typedef
	setup(props: Data, { emit }) {
		const component: EbToggle = new EbToggle();
		
		function toggleSwitch(): void {
			component.toggled.value = !component.toggled.value;
			if (component.toggled.value) {
				emit("toggled");
			}
			else {
				emit("untoggled");
			}
		}

		watchEffect(() => {
			if (props.toggle) {
				component.toggled.value = true;
			}
		});

		return {
			component, toggleSwitch
		};
	}
});
</script>