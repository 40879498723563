
import { defineComponent, onBeforeMount } from "vue";
import hotkeys from "hotkeys-js"; 
import { state } from "./data/providers/global-provider";
import { detectSharedFile } from "./data/providers/share-provider";

export default defineComponent({
	name: "App",
	setup() {
		onBeforeMount(() => {
			detectSharedFile();
		});
		hotkeys("ctrl+k, command+k", function() {
			state.isSearchOpen = true;
		});
		hotkeys("ctrl+/, command+/", function() {
			state.isSearchOpen = true;
		});
	}
});
