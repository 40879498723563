import { state } from "@/data/providers/global-provider";

import { python } from "@/platforms/python";
import { html } from "./html";
import { microbit } from "./microbit";
import { HTMLGenerator } from "./html/html-generator";
import { circuitpython } from "./circuitpython";
import { raspberrypi } from "./raspberrypi";
import { all } from "./general";
import { getDefinitions, getGenerators, getToolbox, activeExtensions } from "@/data/providers/extensions-provider";

export let toolboxXML: string;

export async function getToolboxXml(): Promise<string> {
  toolboxXML = "<xml>";

  (await import("@/platforms/general/definitions")).default(Blockly.Blocks);
  (await import("@/platforms/general/generators")).default(
    Blockly.Python as any
  );
  

    if (state.platform?.name === python.name || state.platform?.name === all.name) {
      (await import("@/platforms/python/blocks/imports/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/imports/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/imports/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/variables/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/variables/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/variables/toolbox.xml")
        .default;

      (
        await import("@/platforms/python/blocks/statements/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/python/blocks/statements/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/statements/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/logic/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/logic/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/logic/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/lists/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/lists/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/lists/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/loops/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/loops/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/loops/toolbox.xml")
        .default;

      (
        await import("@/platforms/python/blocks/definitions/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/python/blocks/definitions/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/python/blocks/definitions/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/math/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/math/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/math/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/turtle/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/turtle/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/turtle/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/graphs/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/graphs/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/graphs/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/random/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/random/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/random/toolbox.xml")
        .default;

      (
        await import("@/platforms/python/blocks/processing/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/python/blocks/processing/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/processing/toolbox.xml")
        .default;

      (await import("@/platforms/python/blocks/requests/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/python/blocks/requests/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/python/blocks/requests/toolbox.xml")
        .default;
    }

    if (state.platform?.name === microbit.name || state.platform?.name === all.name) {
      (await import("@/platforms/microbit/blocks/basic/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/basic/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/basic/toolbox.xml")
        .default;

      (
        await import("@/platforms/microbit/blocks/variables/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/microbit/blocks/variables/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/microbit/blocks/variables/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/display/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/display/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/display/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/buttons/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/buttons/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/buttons/toolbox.xml")
        .default;

      (
        await import("@/platforms/microbit/blocks/accelerometer/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/microbit/blocks/accelerometer/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/microbit/blocks/accelerometer/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/compass/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/compass/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/compass/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/radio/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/radio/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/radio/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/speech/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/speech/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/speech/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/music/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/music/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/music/toolbox.xml")
        .default;

      (
        await import("@/platforms/microbit/blocks/microphone/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/microbit/blocks/microphone/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/microbit/blocks/microphone/toolbox.xml")
        .default;

      (
        await import("@/platforms/microbit/blocks/neopixel/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/microbit/blocks/neopixel/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/neopixel/toolbox.xml")
        .default;

      (await import("@/platforms/microbit/blocks/pins/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/pins/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/pins/toolbox.xml")
        .default;

      for await (const extension of activeExtensions.value){
        toolboxXML += await getToolbox(extension);

        let defsScript = document.createElement("script")
        defsScript.type = "text/javascript";
        defsScript.text = await getDefinitions(extension);
        document.body.appendChild(defsScript);
  
        let genScript = document.createElement("script")
        genScript.type = "text/javascript";
        genScript.text = await getGenerators(extension);
        document.body.appendChild(genScript);
      }
    }

    if (state.platform?.name === html.name || state.platform?.name === all.name) {
      (await import("@/platforms/html/blocks/page/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/page/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/page/toolbox.xml").default;

      (await import("@/platforms/html/blocks/structure/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/structure/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/structure/toolbox.xml").default;

      (await import("@/platforms/html/blocks/text/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/text/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/text/toolbox.xml").default;

      (await import("@/platforms/html/blocks/attributes/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/attributes/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/attributes/toolbox.xml").default;

      (await import("@/platforms/html/blocks/style/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/style/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/style/toolbox.xml").default;

      (await import("@/platforms/html/blocks/media/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/media/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/media/toolbox.xml").default;

      (await import("@/platforms/html/blocks/forms/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/forms/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/forms/toolbox.xml").default;

      (await import("@/platforms/html/blocks/tables/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/tables/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/tables/toolbox.xml").default;

      (await import("@/platforms/html/blocks/lists/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/lists/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/lists/toolbox.xml").default;

      (await import("@/platforms/html/blocks/script/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/html/blocks/script/generators")).default(
        HTMLGenerator as any
      );
      toolboxXML += require("@/platforms/html/blocks/script/toolbox.xml").default;
    }
    
    if (state.platform?.name === circuitpython.name || state.platform?.name === all.name) {
      (
        await import("@/platforms/circuitpython/blocks/basic/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/circuitpython/blocks/basic/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/basic/toolbox.xml").default;
  
      (await import("@/platforms/microbit/blocks/variables/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/microbit/blocks/variables/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/microbit/blocks/variables/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/digital/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/circuitpython/blocks/digital/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/circuitpython/blocks/digital/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/analog/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/circuitpython/blocks/analog/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/circuitpython/blocks/analog/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/neopixel/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/circuitpython/blocks/neopixel/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/circuitpython/blocks/neopixel/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/touch/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/circuitpython/blocks/touch/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/touch/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/servo/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/circuitpython/blocks/servo/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/servo/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/audio/definitions")
      ).default(Blockly.Blocks);
      (await import("@/platforms/circuitpython/blocks/audio/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/audio/toolbox.xml").default;
  
      (await import("@/platforms/circuitpython/blocks/pwm/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/circuitpython/blocks/pwm/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/pwm/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/dotstar/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/circuitpython/blocks/dotstar/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/circuitpython/blocks/dotstar/toolbox.xml").default;
  
      (
        await import("@/platforms/circuitpython/blocks/advanced/definitions")
      ).default(Blockly.Blocks);
      (
        await import("@/platforms/circuitpython/blocks/advanced/generators")
      ).default(Blockly.Python as any);
      toolboxXML += require("@/platforms/circuitpython/blocks/advanced/toolbox.xml").default;
  
      (await import("@/platforms/circuitpython/blocks/cpx/definitions")).default(
        Blockly.Blocks
      );
      (await import("@/platforms/circuitpython/blocks/cpx/generators")).default(
        Blockly.Python as any
      );
      toolboxXML += require("@/platforms/circuitpython/blocks/cpx/toolbox.xml").default;
    }

    if (state.platform?.name === raspberrypi.name || state.platform?.name === all.name) {
      (
          await import("@/platforms/raspberrypi/blocks/basic/definitions")
        ).default(Blockly.Blocks);
        (await import("@/platforms/raspberrypi/blocks/basic/generators")).default(
          Blockly.Python as any
        );
        toolboxXML += require("@/platforms/raspberrypi/blocks/basic/toolbox.xml").default;
    
        (await import("@/platforms/raspberrypi/blocks/variables/definitions")).default(
          Blockly.Blocks
        );
        (await import("@/platforms/raspberrypi/blocks/variables/generators")).default(
          Blockly.Python as any
        );
        toolboxXML += require("@/platforms/raspberrypi/blocks/variables/toolbox.xml").default;
    
        (
          await import("@/platforms/raspberrypi/blocks/minecraft/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/minecraft/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/minecraft/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/gpiozero/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/gpiozero/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/gpiozero/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/sonicpi/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/sonicpi/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/sonicpi/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/pimoroni/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/pimoroni/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/pimoroni/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/requests/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/requests/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/requests/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/sensehat/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/sensehat/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/sensehat/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/bitio/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/bitio/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/bitio/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/turtle/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/turtle/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/turtle/toolbox.xml").default;

        (
          await import("@/platforms/raspberrypi/blocks/picamera/definitions")
        ).default(Blockly.Blocks);
        (
          await import("@/platforms/raspberrypi/blocks/picamera/generators")
        ).default(Blockly.Python as any);
        toolboxXML += require("@/platforms/raspberrypi/blocks/picamera/toolbox.xml").default;
    
    }

    if (state.platform?.name === all.name){
      toolboxXML = "<xml>";
    }

    toolboxXML += "</xml>";

  return toolboxXML;
}
