import { View } from "@/views/constants";
import { createRouter, createWebHistory, RouteLocationNormalized, Router, RouteRecordRaw } from "vue-router";

import DefaultLayout from "../layouts/default-layout.vue";
import EditorLayout from "../layouts/editor-layout.vue";
import BlankLayout from "../layouts/blank-layout.vue";

import Home from "@/views/home/home.vue";
import Editor from "@/views/editor/editor.vue";
import SharedProject from "@/views/editor/editor-shared.vue";
import Login from "@/views/login/login.vue";
import Projects from "@/views/projects/projects.vue";
import Classroom from "@/views/classroom/classroom.vue";
import ClassroomDetail from "@/views/classroom/classroom-detail/classroom-detail.vue";
import ClassroomAssignment from "@/views/classroom/classroom-assignment/classroom-assignment.vue";
import ClassroomJoin from "@/views/classroom/classroom-join/classroom-join.vue";
import Showcase from "@/views/showcase/showcase.vue";
import ShowcaseProject from "@/views/showcase/showcase-project/showcase-project.vue";
import ShowcaseProjects from "@/views/showcase/showcase-projects/showcase-projects.vue";
import Feedback from "@/views/feedback/feedback.vue";
import Guides from "@/views/guides/guides.vue";
import { appInsights } from "@/main";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: View.Home,
		component: Home,
		meta: {
			key: "home",
			layout: DefaultLayout
		}
	},
	{
		path: "/editor",
		name: View.Editor,
		component: Editor,
		meta: {
			key: "editor",
			layout: EditorLayout
		}
	},
	{
		path: "/sharedproject",
		name: View.SharedProject,
		component: SharedProject,
		meta: {
			key: "editor",
			layout: EditorLayout
		}
	},
	{
		path: "/projects",
		name: View.Projects,
		component: Projects,
		meta: {
			key: "projects",
			layout: DefaultLayout
		}
	},
	{
		path: "/classroom",
		name: View.Classroom,
		component: Classroom,
		meta: {
			key: "classroom",
			layout: DefaultLayout
		}
	},
	{
		path: "/classroom/:id",
		name: View.ClassroomDetail,
		component: ClassroomDetail,
		meta: {
			key: "classroom",
			layout: DefaultLayout
		}
	},
	{
		path: "/classroom/:classID/assignment/:assignmentID",
		name: View.ClassroomAssignment,
		component: ClassroomAssignment,
		meta: {
			key: "classroom",
			layout: DefaultLayout
		}
	},
	{
		path: "/classroom/:classID/join",
		name: View.ClassroomJoin,
		component: ClassroomJoin,
		meta: {
			key: "classroom",
			layout: BlankLayout
		}
	},
	{
		path: "/login",
		name: View.Login,
		component: Login,
		meta: {
			layout: DefaultLayout
		}
	},
	{
		path: "/showcase",
		name: View.Showcase,
		component: Showcase,
		meta: {
			key: "showcase",
			layout: DefaultLayout
		}
	},
	{
		path: "/showcase/:id",
		name: View.ShowcaseProject,
		component: ShowcaseProject,
		meta: {
			key: "showcase",
			layout: DefaultLayout
		}
	},
	{
		path: "/showcase/projects/:mode",
		name: View.ShowcaseProjects,
		component: ShowcaseProjects,
		meta: {
			key: "showcase",
			layout: DefaultLayout
		}
	},
	{
		path: "/feedback",
		name: View.Feedback,
		component: Feedback,
		meta: {
			key: "feedback",
			noMargin: true,
			layout: DefaultLayout
		}
	},
	{
		path: "/guides",
		name: View.Guides,
		component: Guides,
		meta: {
			key: "guides",
			layout: DefaultLayout
		}
	}
];

const router: Router = createRouter({
	history: createWebHistory("/legacy"),
	routes,
});

router.beforeEach((to: RouteLocationNormalized) => {
	if (appInsights) {
		appInsights.trackPageView({
			name: to.name as string
		});
	}
});

export default router;
