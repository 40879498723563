import { getTranslatedText } from "@/data/providers/localization-provider";
import { NavigationItem } from "@/data/providers/navigation-provider";
import router from "@/router";
import { ref, Ref } from "@vue/reactivity";

export const isLanguageSelectorOpen: Ref<boolean> = ref(false);

export class EbNav {
	/**
	 * Get Translated text for the home view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("navigation", key);
	}

	public closeDropdown(): void {
		isLanguageSelectorOpen.value = false;
	}

	public setLanguage(code: string): void {
		localStorage.setItem("language", code);
		location.reload();
	}

	public onItemClick(item: NavigationItem): void {
		if (item.click) {
			item.click();
		}
		else if (item.path) {
			router.push(item.path);
		}
	}
}