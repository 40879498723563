import * as firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/functions";
import "firebase/compat/app-check";
import router from "@/router/index";
import { ref, Ref } from "vue";
import { useToast, POSITION } from "vue-toastification";
import { initializeAppCheck, ReCaptchaV3Provider, getToken, AppCheckTokenResult, AppCheck } from "@firebase/app-check";
import { HeapUtilities } from "@/utilities/heap-utilities";

interface FirebaseConfig {
	apiKey?: string;
	authDomain?: string;
	databaseURL?: string;
	projectId?: string;
	storageBucket?: string;
	messagingSenderId?: string;
	appId?: string;
	measurementId?: string;
}

export enum FirebaseProviders {
	Google = "Google",
	Microsoft = "Microsoft"
}

const firebaseConfig: FirebaseConfig = {
	apiKey: process.env.VUE_APP_API_KEY,
	authDomain: process.env.VUE_APP_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_DATABASE_URL,
	projectId: process.env.VUE_APP_PROJECT_ID,
	storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APP_ID,
	measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

const app: firebase.default.app.App = firebase.default.initializeApp(firebaseConfig);

class Authentication {
	public auth: firebase.default.auth.Auth = firebase.default.auth();

	public db: firebase.default.firestore.Firestore = firebase.default.firestore();

	public functions: firebase.default.functions.Functions = firebase.default.functions();

	public realtime: firebase.default.database.Database = firebase.default.database();

	public currentUser: Ref<firebase.default.User | null> = ref(this.auth.currentUser);

	public toast: any = useToast();

	public signInUserWithEmailAndPassword(email: string, password: string): void {
		this.auth.signInWithEmailAndPassword(email, password)
			.then(() => {
				router.push({ path: "/" });
			})
			.catch((error: Error) => {
				console.log(error);
				this.toast.error(error.message, { position: POSITION.TOP_RIGHT }); 
			});
	}

	public registerUserWithEmailAndPassword(email: string, password: string): void {
		this.auth.createUserWithEmailAndPassword(email, password)
			.then(() => {
				router.push({ path: "/" });
			})
			.catch((error: Error) => {
				console.log(error);
				this.toast.error(error.message, { position: POSITION.TOP_RIGHT }); 
			});
	}

	public sendResetPasswordEmail(email: string): void {
		this.auth.sendPasswordResetEmail(email)
			.then(() => {
				this.toast.success(`Password reset email sent to ${email}`, { position: POSITION.TOP_RIGHT }); 
			})
			.catch((error: Error) => {
				this.toast.error(error.message, { position: POSITION.TOP_RIGHT }); 
			});
	}

	public signInUserWithProvider(provider: FirebaseProviders): void {
		let signInProvider: any;
		switch (provider) {
			case FirebaseProviders.Google:
				signInProvider = new firebase.default.auth.GoogleAuthProvider();
				break;
			case FirebaseProviders.Microsoft:
				signInProvider = new firebase.default.auth.OAuthProvider("microsoft.com");
				break;
		}
		this.auth.signInWithPopup(signInProvider).then(() => {
			router.push({ path: "/" });
		}).catch((error: Error) => {
			this.toast.error(error.message, { position: POSITION.TOP_RIGHT }); 
		});
	}

	public signOut(): void {
		this.auth.signOut().then(() => {
			this.currentUser.value = null;
		});
	}

	public upgradeToV4Account(): void {
		const userRef: firebase.default.firestore.DocumentReference = this.db.collection("users").doc(this.currentUser.value?.uid);
		userRef.get().then((snapshot: firebase.default.firestore.DocumentSnapshot) => {
			if (!snapshot.exists) {
				userRef.set({
					uid: this.currentUser.value?.uid,
					image: this.currentUser.value?.photoURL,
					name: this.currentUser.value?.displayName,
					email: this.currentUser.value?.email
				});
			}
		});
	}

	public async getUserDetails(id: string): Promise<object | undefined> {
		let user: object | undefined;
		const getUser: firebase.default.functions.HttpsCallable = this.functions.httpsCallable("getUser");

		await getUser({ userId: id }).then((result: firebase.default.functions.HttpsCallableResult) => {
			user = result.data;
		});
		
		return user;
	}

	public async updateName(name: string): Promise<void> {
		const userRef: firebase.default.firestore.DocumentReference = this.db.collection("users").doc(this.currentUser.value?.uid);
		await authentication.currentUser.value?.updateProfile({
			displayName: name
		});
		await userRef.update({
			name
		});
		location.reload();
	}

	public appCheck: AppCheck | undefined;

	public initializeAppCheck(): void {
		this.appCheck = initializeAppCheck(app, {
			provider: new ReCaptchaV3Provider(process.env.VUE_APP_APP_CHECK_KEY as string)
		});
	}

	public getAppCheckToken(): Promise<AppCheckTokenResult> {
		return getToken(this.appCheck as AppCheck);
	}
}

export const authentication: Authentication = new Authentication();

authentication.auth.onAuthStateChanged((user: firebase.default.User | null) => {
	if (user) { 
		authentication.currentUser.value = user;
		authentication.upgradeToV4Account();

	}

	// Identify heap user.
	heap.identify(HeapUtilities.getUUID());

	heap.addUserProperties({
		edublocks_user_type: user ? "registered" : "anonymous"
	});

	HeapUtilities.registerUser();
});