/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Tab } from "@/components/eb-selector/eb-selector";
import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import router from "@/router";
import { reactive, toRaw } from "vue";
import { ClassroomDetailState } from "./classroom-detail-state";
import * as firebase from "firebase/compat/app";
import { getAllProjectsAsync } from "@/data/providers/files-provider";
import { Project } from "./classroom-detail-types";

export class ClassroomModel {
	// State for Classroom Detail View
	public state: ClassroomDetailState = reactive(new ClassroomDetailState());

	/**
	 * Get Translated text for the classroom view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("classroom", key);
	} 

	/**
	 * Initialize the classroom view
	 */
	public init(): void {
		this.getClassroom();
		this.getProjectsForDropdown();
	}

	public reset(): void {
		this.state.students = [];
		this.state.admins = [];
		this.state.activeAssignments = [];
		this.state.pastAssignments = [];
		this.state.classroom = undefined;
	}

	public isUserAdmin(): boolean {
		let isUserAdmin: boolean = false;
		if (this.state.classroom?.data.admins.includes(authentication.currentUser.value?.uid)) {
			isUserAdmin = true;
		}
		if (this.state.classroom?.data.students.includes(authentication.currentUser.value?.uid)) {
			isUserAdmin = false;
		}
		return isUserAdmin;
	}

	public deleteClassroom(): void {
		authentication.db.collection("classrooms").doc(router.currentRoute.value.params.id as string).delete().then(() => {
			router.push({path: "/classroom"});
		});
	}

	/**
	 * Get all classrooms for current user
	 */
	 public async getClassroom(): Promise<void> {
		this.reset();
		this.state.isBusy = true;
		await authentication.db.collection("classrooms").doc(router.currentRoute.value.params.id as string).get().then((doc: firebase.default.firestore.DocumentSnapshot) => {
			this.state.classroom = { id: doc.id, data: doc.data() };
			this.state.classroom?.data.students.forEach((student: string) => {
				authentication.getUserDetails(student).then((data: any | undefined) => {
					this.state.students.push(data);
				});
			});
			this.state.classroom?.data.admins.forEach((admin: string) => {
				authentication.getUserDetails(admin).then((data: any | undefined) => {
					this.state.admins.push({title: data?.name, value: data?.name});
				});
			});
			this.state.classroom?.data.assignments.forEach((assignment: string) => {
				authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").doc(assignment).get().then((doc: firebase.default.firestore.DocumentSnapshot) => {
					if (this.isAssignmentActive(doc.data()?.due)) {
						this.state.activeAssignments.push({id: doc.id, data: doc.data()});
					}
					else {
						this.state.pastAssignments.push({id: doc.id, data: doc.data()});
					}
				});
			});
		}).finally(() => {
			this.state.isBusy = false;
		});
	}

	public getTabs(): Array<Tab> {
		return [
			{
				title: this.getText("active-assignments"), 
				key: "active-assignments", 
				visible: true,
				count: this.state.activeAssignments.length,
				action: (): void => {
					this.state.selected = "active-assignments";
				}
			},
			{
				title: this.getText("past-assignments"), 
				key: "past-assignments", 
				count: this.state.pastAssignments.length,
				visible: true,
				action: (): void => {
					this.state.selected = "past-assignments";
				}
			}
		];
	}

	public isAssignmentActive(date: string): boolean {
		return new Date() < new Date(date);
	}

	public gradients: Array<string> = [
		"bg-gradient-to-b from-blue-300 to-blue-600",
		"bg-gradient-to-b from-blue-500 to-rose-500",
		"bg-gradient-to-b from-green-300 to-cyan-500",
		"bg-gradient-to-b from-amber-300 to-orange-500",
		"bg-gradient-to-b from-fuchsia-500 to-purple-600"
	]

	public getRandomGradient(): string {
		return this.gradients[Math.floor(Math.random() * this.gradients.length)];
	}

	public newAssignmentFormData: any = reactive({
		title: "",
		description: "",
		due: "",
		marks: 0,
		project: "",
	}) 

	public async createNewAssignment(): Promise<void> {
		await authentication.db.collection("classrooms").doc(this.state.classroom?.id).collection("assignments").add({
			title: this.newAssignmentFormData.title,
			description: this.newAssignmentFormData.description,
			due: this.newAssignmentFormData.due,
			marks: this.newAssignmentFormData.marks,
			gradient: this.getRandomGradient(),
			project: JSON.stringify(this.newAssignmentFormData.project),
			submissions: []
		}).then((doc: firebase.default.firestore.DocumentReference) => {
			authentication.db.collection("classrooms").doc(this.state.classroom?.id).update({
				assignments: firebase.default.firestore.FieldValue.arrayUnion(doc.id)
			});
		}).finally(() => {
			this.getClassroom();
			this.state.isCreateAssignmentModalOpen = false;
		});
	}

	public getProjectsForDropdown(): void {
		getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
			if (files) {
				files.items.forEach((ref: firebase.default.storage.Reference) => {
					ref.getDownloadURL().then((url: string) => {
						ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
							this.state.files.push({title: ref.name, value: {label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta, date: meta.updated}});
							this.state.files.sort((a: Project, b: Project) => {
								return new Date(a.value.date).getTime() - new Date(b.value.date).getTime();
							}).reverse();
							this.state.isBusy = false;
						});
					});
				});
			}
		});
	}

	public getClassroomJoinCode(): void {
		const content: object = {
			dynamicLinkInfo: {
				domainUriPrefix: "https://join.edublocks.org",
				link: `http://${location.host}/classroom/${this.state.classroom?.id}/join`
			},
			suffix: {
				option: "SHORT"
			}
		};
		fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_API_KEY}`, {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(content)
		}).then((response: Response) => {
			response.json().then((json: any) => {
				this.state.joinCode = json.shortLink.replace(`https://join.edublocks.org/`, "");
				this.state.isAddStudentsModalOpen = true;
			});
		});
	}

	public async removeStudentFromClass(userID: string | undefined): Promise<void> {
		await authentication.db.collection("classrooms").doc(this.state.classroom?.id).update({
			students: firebase.default.firestore.FieldValue.arrayRemove(userID)
		}).then(() => {
			this.getClassroom();
		});
	}
} 