import { Platform } from "@/data/providers/platform-provider";
import { EditorButtons } from "@/views/editor/editor-type";

export const all: Platform = {
	name: "All",
	key: "all",
	image: "/legacy/assets/images/platforms/python.png",
	icon: "/legacy/assets/images/platforms/python-icon.svg",
	color: "bg-gradient-to-b from-blue-300 to-blue-600",
	fileExtension: "py",
	actions: [EditorButtons.Save, EditorButtons.Run]
};