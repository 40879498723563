import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { reactive } from "@vue/reactivity";
import { ShowcaseState } from "./showcase-state";
import { ShowcaseProject } from "./showcase-types";

export class ShowcaseModel {
	// State for Showcase View
	public state: ShowcaseState = reactive(new ShowcaseState());
		
	/**
	 * Get Translated text for the showcase view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("showcase", key);
	}

	public init(): void {
		this.getLatestProjects();
		this.getPythonProjects();
		this.getHTMLProjects();
		this.getMicrobitProjects();
		this.getCircuitpythonProjects();
	}

	public getLatestProjects(): void {
		this.state.latestProjectsBusy = true;
		authentication.db.collection("showcase").orderBy("created", "desc").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.latestProjects.push(project);
				}
				this.state.latestProjectsBusy = false;
			});
		}).catch((error: Error) => {
			console.log(error);
		});
	}

	public getPythonProjects(): void {
		this.state.pythonProjectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", "Python 3").orderBy("created", "desc").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.pythonProjects.push(project);
				}
				this.state.pythonProjectsBusy = false;
			});
		});
	}

	public getHTMLProjects(): void {
		this.state.HTMLProjectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", "HTML").orderBy("created", "desc").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.HTMLProjects.push(project);
				}
				this.state.HTMLProjectsBusy = false;
			});
		});
	}

	public getMicrobitProjects(): void {
		this.state.microbitProjectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", "BBC micro:bit").orderBy("created", "desc").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.microbitProjects.push(project);
				}
				this.state.microbitProjectsBusy = false;
			});
		});
	}

	public getCircuitpythonProjects(): void {
		this.state.circuitpythonProjectsBusy = true;
		authentication.db.collection("showcase").where("platform", "==", "CircuitPython").orderBy("created", "desc").limit(10).get().then((snapshot: firebase.default.firestore.QuerySnapshot) => {
			snapshot.docs.forEach((snapshot: firebase.default.firestore.QueryDocumentSnapshot) => {
				if (snapshot.data()) {
					const project: ShowcaseProject = snapshot.data() as ShowcaseProject;
					project.id = snapshot.id;
					this.state.circuitpythonProjects.push(project);
				}
				this.state.circuitpythonProjectsBusy = false;
			});
		});
	}
}