import { microbit } from "@/platforms/microbit";
import { python } from "@/platforms/python";
import { html } from "@/platforms/html";
import { circuitpython } from "@/platforms/circuitpython";
import { raspberrypi } from "@/platforms/raspberrypi";
import { EditorButtons } from "@/views/editor/editor-type";

export enum Action {
	RunPython = "RunPython",
	DownloadHex = "DownloadHex"
}

export interface Platform {
	name: string;
	image: string;
	icon?: string;
	key: string;
	description?: string;
	color: string;
	actions?: Array<EditorButtons>;
	fileExtension: string;
	extensionsActive?: boolean;
}

export const platforms: Array<Platform> = [ python, html, microbit, circuitpython, raspberrypi ];

export function getPlatformFromName(name: string | undefined): Platform {
	return platforms.filter((platform: Platform) => {
		return platform.name === name;
	})[0];
}

export function getNewModeName(name: string): string {
	if (name === "Python") {
		return "python";
	} 
	else if (name === "microbit") {
		return "microbit";
	} 
	else if (name === "HTML") {
		return "html";
	} 
	else if (name === "CircuitPython") {
		return "circuitpython";
	} 
	else if (name === "RPi") {
		return "raspberry-pi";
	}
	else {
		return "";
	}
}