import { Platform } from "@/data/providers/platform-provider";
import { ref, Ref } from "vue";
import { Terminal } from "xterm";
import { ShowcaseProject } from "../showcase/showcase-types";
import { EditorView } from "./editor-type";

export const blocklyDiv: Ref = ref();
export const xmlCode: Ref<string> = ref("");
export const code: Ref<string> = ref("");

export class EditorState {
	// Store local selected platform
	public selected: Platform | undefined;

	// Store editor view
	public view: EditorView | undefined;

	// Store visible state for code window
	public isCodeWindowActive: boolean = true;

	// Store visible state for trinket window
	public isTrinketWindowActive: boolean = false;

	public isSerialWindowActive: boolean = false;

	// Store visible state for remote terminal window
	public isRemoteTerminalWindowActive: boolean = false;

	// Store visible state for HTML Preview
	public isHTMLPreviewActive: boolean = false;

	// Store visible state for Blockly
	public isBlockEditorActive: boolean = false;

	public isShowcasePublishModalOpen: boolean = false;

	// Store save state for file
	public isSaved: boolean = false;

	public savedMessage: boolean = false;

	public showcasePublishForm: ShowcaseProject = { title: "", projectURL: "", description: "", cloneURL: "", platform: "", image: "", uid: "" };

	public isThumbnailLoading: boolean = false;

	public flashProgress: number = 0;

	public isFlashing: boolean = false;

	public isExtensionModalOpen: boolean = false;

	public serialTerminal: Terminal | undefined;

	public toolboxWidth: number = 0;

	public extensionURL: string = "";

	public isCodeEditWarningOpen: boolean = false;
	
	public hasCodeEditWarningBeenSeen: boolean = false;
}