<template>
	<header class="w-full">
		<div class="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-800">
			<div class="flex justify-between flex-1 px-4 sm:px-8">
				<div class="flex items-center justify-between flex-1">
					<button
						class="flex items-center py-2 space-x-3 leading-6 text-gray-400 transition-colors duration-200 group sm:space-x-4 hover:text-gray-600 focus:outline-none"
						@click="state.isSearchOpen = !state.isSearchOpen"
					>
						<SearchIcon class="flex-shrink-0 w-5 h-5 text-gray-400 transition-colors duration-200 group-hover:text-gray-700" />
						<span>
							{{ getTranslatedText("search", "search-for-anything") }}
						</span>
						<span
							class="hidden sm:block text-gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-300 rounded-md"
							style="opacity: 1;"
						>
							<kbd class="font-sans">
								<abbr
									v-if="isMacOS()"
									title="Command"
									class="no-underline"
								>
									⌘
								</abbr>
								<abbr
									v-else
									title="Command"
									class="no-underline"
								>
									CTRL
								</abbr>
							</kbd>
							<kbd class="font-sans">K</kbd>
						</span>
					</button>
					<EbUserMenu class="ml-auto" />
				</div>
			</div>
		</div>
	</header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { state } from "@/data/providers/global-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";

export default defineComponent({
	name: ComponentName.EbHeader,
	setup() {
		function isMacOS(): boolean {
			return navigator.userAgent.indexOf("Mac OS X") !== -1;
		}

		return { isMacOS, state, getTranslatedText };
	}
});
</script>
