
import { defineComponent, ref, Ref } from "vue";

import { defaultNavigation } from "@/data/providers/navigation-provider";

export default defineComponent({
	name: "DefaultLayout",
	setup() {
		const isBannerOpen: Ref<boolean> = ref(false);
			
		if (!localStorage.getItem("v3Banner")) {
			isBannerOpen.value = true;
			localStorage.setItem("v3Banner", "true");
		}

		function closeBanner(): void {
			localStorage.setItem("v3Banner", "true");
			isBannerOpen.value = false;
		}

		return { defaultNavigation, isBannerOpen, closeBanner };
	}
});
