
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
 
export default defineComponent({
	name: ComponentName.EbSlideout,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		visible: {
			type: Boolean
		}
	},
	emits: ["exit"]
});
