/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable brace-style */
import { SelectOption } from "@/components/eb-select/eb-select-types";
import { DropdownOption } from "@/components/eb-thumbnail-card/eb-thumbnail-card-types";
import { authentication } from "@/data/providers/authentication-provider";
import { activeExtensions } from "@/data/providers/extensions-provider";
import { FirebaseFile, getAllProjectsAsync, getLocalDataForFile, deleteFirebaseFileAsync, openLocalFile, saveFileToFirebaseAsync } from "@/data/providers/files-provider";
import { state } from "@/data/providers/global-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { Platform, platforms } from "@/data/providers/platform-provider";
import { appInsights } from "@/main";
import router from "@/router";
import { reactive, toRaw } from "vue";
import { code, xmlCode } from "../editor/editor-state";
import { ProjectsState } from "./projects-state";

export class ProjectsModel {
	// State for Projects View
	public state: ProjectsState = reactive(new ProjectsState());

	/**
	 * Get Translated text for the projects view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("projects", key);
	}

	/**
	 * Initalize projects view
	 */
	public init(): void {
		this.getAllFilesFromFirebase();
	}

	/**
	 * Open a project in the editor
	 */
	public openProject(project: FirebaseFile): void {
		fetch(project.downloadURL).then((response: Response) => {
			return response.blob();
		}).then((blob: Blob) => {
			blob.text().then((text: string) => {
				state.filename = getLocalDataForFile(project).shortTitle;
				state.platform = getLocalDataForFile(project).platform;
				appInsights?.trackEvent({
					name: `Load ${state.platform?.name as string} Mode`
				});
				state.isFirebaseProject = true;
				state.currentProject = project.ref;
				const codeSplit: Array<string> = text.split(/\r?\n/);
				if (codeSplit[codeSplit.length-2] && codeSplit[codeSplit.length-2].includes("[")) {
					activeExtensions.value = JSON.parse(codeSplit[codeSplit.length-2]);
				}
				if (project.label.split(`(${state.platform?.key})`)[1] === state.platform?.fileExtension) {
					state.textOnlyMode = true;
					code.value = text;
				}
				else {
					xmlCode.value = text;
				}
				router.push({path: "/editor"});
			});
		});
	}

	public importFile(): void {
		openLocalFile().then((text: string) => {
			const codeSplit: Array<string> = text.split(/\r?\n/);
			if (codeSplit[0].includes("xml")) {
				xmlCode.value = text;
			}
			else {
				state.textOnlyMode = true;
				code.value = text;
			}
			if (codeSplit[codeSplit.length-2] && codeSplit[codeSplit.length-2].includes("[")) {
				activeExtensions.value = JSON.parse(codeSplit[codeSplit.length-2]);
			}
			this.state.isImportModalOpen = true;
		});
	}

	public openImportedFile(): void {
		router.push({path: "/editor"});
		saveFileToFirebaseAsync();
	}

	public getPlatformOptions(): Array<SelectOption> {
		const options: Array<SelectOption> = [];
		
		platforms.forEach((platform: Platform) => {
			options.push({title: platform.name, value: platform});
		});
		
		return options;
	}

	/**
	 * Get all projects from firebase and push to view state
	 */
	public getAllFilesFromFirebase(): void {
		this.state.projects = [];
		if (authentication.currentUser.value) {
			this.state.isBusy = true;
			getAllProjectsAsync().then((files: firebase.default.storage.ListResult | undefined) => {
				if (files?.items) {
					if (files?.items.length > 0) {
						files.items.forEach((ref: firebase.default.storage.Reference) => {
							ref.getDownloadURL().then((url: string) => {
								ref.getMetadata().then((meta: firebase.default.storage.FullMetadata) => {
									this.state.projects.push({label: ref.name, ref: toRaw(ref), downloadURL: url, metadata: meta, date: meta.updated});
									this.state.projects.sort((a: FirebaseFile, b: FirebaseFile) => {
										return new Date(a.date).getTime() - new Date(b.date).getTime();
									}).reverse();
									this.state.isBusy = false;
								});
							});
						});
					}
					else {
						this.state.isBusy = false;
					}
				}
			});
		}
	}

	public projectDropdown(project: FirebaseFile): Array<DropdownOption> {
		return [ 
			{title: this.getText("open"), icon: "FolderOpenIcon", action: (): void => { this.openProject(project); }},
			{title: this.getText("delete"), icon: "TrashIcon", action: (): void => { deleteFirebaseFileAsync(project.ref).then(() => { this.getAllFilesFromFirebase(); }).catch(() => { this.state.isDeleteErrorModelOpen = true; }); }},
		];
	}
} 