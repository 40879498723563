import { appInsights } from "@/main";
import { circuitpython } from "@/platforms/circuitpython";
import { html } from "@/platforms/html";
import { microbit } from "@/platforms/microbit";
import { python } from "@/platforms/python";
import { raspberrypi } from "@/platforms/raspberrypi";
import router from "@/router";
import { code, xmlCode } from "@/views/editor/editor-state";
import { state } from "./global-provider";
import { authentication } from "./authentication-provider";

function getFileUrl(encodedUrl: string): string {
	const url: URL = new URL(atob(encodedUrl));
	url.searchParams.delete("token");
	return url.toString();
}

export async function detectSharedFile(): Promise<void> {
	const url: string = window.location.href.toString();

	if (url.includes("#share") || url.includes("#embed")) {
		if (url.includes("?Python")) {
			state.platform = python;
		}

		if (url.includes("?MicroBit")) {
			state.platform = microbit;
		}

		if (url.includes("?microbit")) {
			state.platform = microbit;
		}

		if (url.includes("?CircuitPython")) {
			state.platform = circuitpython;
		}

		if (url.includes("?HTML")) {
			state.platform = html;
		}

		if (url.includes("?RPi")) {
			state.platform = raspberrypi;
		}

		const fileURL: string = window.location.href.substring(
			window.location.href.lastIndexOf("?") + 1
		);

		const decodedfileURL: string = getFileUrl(fileURL);

		fetch(decodedfileURL, {
			headers: {
				"X-Firebase-AppCheck": (await authentication.getAppCheckToken()).token
			}
		})
			.then((response: Response) => {
				response.text().then((text: string) => {
					const codeSplit: Array<string> = text.split(/\r?\n/);
					if (codeSplit[0].includes("xml")) {
						xmlCode.value = text;
					}
					else {
						state.textOnlyMode = true;
						code.value = text;
					}
					url.includes("#share") ? null : state.isSharedProject = true;
					url.includes("#share") ? router.push({path: "/editor"}) : router.push({path: "/sharedproject"});
				});
			});

		appInsights?.trackEvent({
			name: `Load ${state.platform?.name as string} Mode`
		});
	}
}