
import { defineComponent, onMounted } from "vue";
import { GuidesModel } from "./guides-model";
import { View } from "../constants";
import { platforms } from "@/data/providers/platform-provider";

export default defineComponent({
	name: View.Guides,
	setup() {
		const view: GuidesModel = new GuidesModel();

		onMounted(() => {
			view.getAllGuides();
		});

		return { view, platforms };
	}
});
