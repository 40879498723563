
import { defineComponent, onMounted } from "vue";
import { ShowcaseModel } from "./showcase-model";
import { View } from "../constants";
import { getShowcaseProjectPlatform } from "@/data/providers/showcase-provider";

export default defineComponent({
	name: View.Showcase,
	setup() {
		const view: ShowcaseModel = new ShowcaseModel();

		onMounted(() => {
			view.init();
		});

		return { view, getShowcaseProjectPlatform };
	}
});
