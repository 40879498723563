<template>
	<div class="h-screen flex flex-col">
		<div
			v-if="isBannerOpen"
			class="bg-blue-500"
		>
			<div class="py-3 px-8">
				<div class="flex items-center justify-between flex-wrap">
					<div class="w-0 flex-1 flex items-center">
						<span class="flex p-2 rounded-lg bg-blue-700">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="h-6 w-6 text-white"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
								/>
							</svg>

						</span>
						<p class="ml-3 font-medium text-white truncate">
							<span>
								<span>We use cookies to make sure you get the best experience. </span>
								<a
									href="https://edublocks.org/privacy"
									class="opacity-80"
								>Privacy Policy</a>
							</span>
						</p>
					</div>
					<div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						<a
							class="flex cursor-pointer items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-500 bg-white hover:bg-opacity-95"
							@click="closeBanner()"
						>
							Got it
						</a>
					</div>
					<div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
						<button
							type="button"
							class="-mr-1 flex p-2 rounded-md hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
							@click="closeBanner()"
						>
							<span class="sr-only">Dismiss</span>
							<!-- Heroicon name: outline/x -->
							<svg
								class="h-6 w-6 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="flex h-full overflow-hidden bg-gray-50 dark:bg-gray-900">
			<EbNav :options="defaultNavigation" />
			<div class="flex flex-col flex-1 overflow-hidden">
				<EbHeader />
				<div class="w-full h-full overflow-y-auto">
					<div
						:class="this.$router.currentRoute.value.meta.noMargin ? '' : 'sm:px-6 lg:px-8 py-8 max-w-[110rem]'"
						class="w-full h-full mx-auto"
					>
						<slot />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";

import { defaultNavigation } from "@/data/providers/navigation-provider";

export default defineComponent({
	name: "DefaultLayout",
	setup() {
		const isBannerOpen: Ref<boolean> = ref(false);
			
		if (!localStorage.getItem("v3Banner")) {
			isBannerOpen.value = true;
			localStorage.setItem("v3Banner", "true");
		}

		function closeBanner(): void {
			localStorage.setItem("v3Banner", "true");
			isBannerOpen.value = false;
		}

		return { defaultNavigation, isBannerOpen, closeBanner };
	}
});
</script>
