<template>
	<div class="space-y-8">
		<div class="overflow-hidden rounded-lg shadow-xl bg-gradient-to-b from-fuchsia-500 to-purple-600 lg:grid lg:grid-cols-2 lg:gap-4">
			<div class="px-6 pt-10 pb-12 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
				<div class="lg:self-center">
					<h2 class="text-3xl font-extrabold text-white sm:text-4xl">
						<span class="block opacity-70">{{ view.getText('featured-project') }}</span>
						<span class="block">{{ view.getText('intro-html') }}</span>
					</h2>
					<p class="mt-4 text-lg leading-6 text-white opacity-90">
						{{ view.getText('featured-project-description') }}
					</p>
					<a
						class="inline-flex items-center px-5 py-3 mt-8 text-base font-medium text-purple-600 bg-white border border-transparent rounded-md shadow cursor-pointer hover:bg-indigo-50"
						@click="this.$router.push({path: '/showcase/OwRbZ5M7VbTiuU0AQIy8'})"
					>
						{{ view.getText('view-project') }}
					</a>
				</div>
			</div>
			<div class="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
				<img
					class="object-cover object-left-top transform translate-x-6 translate-y-6 rounded-md sm:translate-x-16 lg:translate-y-20"
					src="/legacy/assets/images/general/showcase-screenshot.png"
				>
			</div>
		</div>

		<div>
			<EbPageHeader :title="view.getText('latest-projects')" />
			<div class="w-full pb-1">
				<splide
					v-if="!view.state.latestProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem', trimSpace: 'move', padding: '0rem'}"
				>
					<splide-slide
						v-for="project in view.state.latestProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="project.platform"
							:gradient-image="getShowcaseProjectPlatform(project).icon"
							:gradient="getShowcaseProjectPlatform(project).color"
							fixed
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
				<div
					v-else
					class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max"
				>
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						fixed
					/>
				</div>
			</div>
		</div>
		
		<div>
			<EbPageHeader :title="`Python ${view.getText('projects')}`">
				<EbButton
					:text="view.getText('view-all')"
					color="lightBlue"
					fa-icon="fas fa-eye"
					@click="this.$router.push({path: '/showcase/projects/python'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1">
				<splide
					v-if="!view.state.pythonProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem', trimSpace: 'move'}"
				>
					<splide-slide
						v-for="project in view.state.pythonProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="project.platform"
							:gradient-image="getShowcaseProjectPlatform(project).icon"
							:gradient="getShowcaseProjectPlatform(project).color"
							fixed
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
				<div
					v-else
					class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max"
				>
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						fixed
					/>
				</div>
			</div>
		</div>
		
		<div>
			<EbPageHeader :title="`HTML ${view.getText('projects')}`">
				<EbButton
					:text="view.getText('view-all')"
					color="lightBlue"
					fa-icon="fas fa-eye"
					@click="this.$router.push({path: '/showcase/projects/html'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<splide
					v-if="!view.state.HTMLProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem'}"
				>
					<splide-slide
						v-for="project in view.state.HTMLProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="project.platform"
							:gradient-image="getShowcaseProjectPlatform(project).icon"
							:gradient="getShowcaseProjectPlatform(project).color"
							fixed
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
				<div
					v-else
					class="grid w-full grid-flow-col gap-5 overflow-x-auto auto-cols-max"
				>
					<EbThumbnailCardSkeleton
						v-for="card in 5"
						:key="card"
						fixed
					/>
				</div>
			</div>
		</div>
		
		<div>
			<EbPageHeader :title="`micro:bit ${view.getText('projects')}`">
				<EbButton
					:text="view.getText('view-all')"
					color="lightBlue"
					fa-icon="fas fa-eye"
					@click="this.$router.push({path: '/showcase/projects/microbit'})"
				/>
			</EbPageHeader>			
			<div class="w-full pb-1 overflow-x-auto">
				<splide
					v-if="!view.state.microbitProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem', trimSpace: 'move', padding: '0rem'}"
				>
					<splide-slide
						v-for="project in view.state.microbitProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="project.platform"
							:gradient-image="getShowcaseProjectPlatform(project).icon"
							:gradient="getShowcaseProjectPlatform(project).color"
							fixed
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
			</div>
		</div>
		
		<div class="pb-8">
			<EbPageHeader :title="`CircuitPython ${view.getText('projects')}`">
				<EbButton
					:text="view.getText('view-all')"
					color="lightBlue"
					fa-icon="fas fa-eye"
					@click="this.$router.push({path: '/showcase/projects/circuitpython'})"
				/>
			</EbPageHeader>
			<div class="w-full pb-1 overflow-x-auto">
				<splide
					v-if="!view.state.circuitpythonProjectsBusy"
					:options="{autoWidth: true, pagination: false, gap: '1.25rem', trimSpace: 'move', padding: '0rem'}"
				>
					<splide-slide
						v-for="project in view.state.circuitpythonProjects"
						:key="project"
					>
						<EbThumbnailCard
							:title="project.title"
							:subtitle="project.platform"
							:gradient-image="getShowcaseProjectPlatform(project).icon"
							:gradient="getShowcaseProjectPlatform(project).color"
							fixed
							@click="this.$router.push({path: `/showcase/${project.id}`})"
						/>
					</splide-slide>
				</splide>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { ShowcaseModel } from "./showcase-model";
import { View } from "../constants";
import { getShowcaseProjectPlatform } from "@/data/providers/showcase-provider";

export default defineComponent({
	name: View.Showcase,
	setup() {
		const view: ShowcaseModel = new ShowcaseModel();

		onMounted(() => {
			view.init();
		});

		return { view, getShowcaseProjectPlatform };
	}
});
</script>