/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { authentication } from "./authentication-provider";
import * as firebase from "firebase/compat/app";
import { Platform, getNewModeName, platforms } from "./platform-provider";
import { state } from "./global-provider";
import { code, xmlCode } from "@/views/editor/editor-state";
import { saveAs } from "file-saver";
import { activeExtensions } from "./extensions-provider";

export interface FirebaseFile {
	label: string;
	ref: firebase.default.storage.Reference;
	downloadURL: string;
	metadata?: firebase.default.storage.FullMetadata;
	date: string;
}

export interface FileLocalData {
	platform?: Platform;
	shortTitle?: string;
} 

export async function getAllProjectsAsync(): Promise<firebase.default.storage.ListResult | undefined> {
	let files: firebase.default.storage.ListResult | undefined;
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}`);
		await ref.listAll().then((res: firebase.default.storage.ListResult) => {
			files = res;
		});
	} 
	return files;
}

export function getLocalDataForFile(file: FirebaseFile): FileLocalData {
	const data: FileLocalData = {};
	platforms.forEach((platform: Platform) => {
		if (file.label.includes(`(${platform.key})`)) {
			data.shortTitle = file.label.split(`(${platform.key})`)[0];
			data.platform = platform;
		}
	});
	return data;
}

export async function deleteFirebaseFileAsync(ref: firebase.default.storage.Reference): Promise<void> {
	const metadata: any = await ref.getMetadata();
	if (metadata.customMetadata && metadata.customMetadata.deleteDisabled === "true") {
		throw Error("Delete functionality locked for this project");
	}
	else {
		await ref.delete();
	}
}

async function createV5Project(): Promise<void> {
	if (state.platform) {
		const project: object = {
			name: state.filename,
			path: state.currentProject?.fullPath,
			access: "private",
			mode: getNewModeName(state.platform.key),
			type: state.textOnlyMode ? "text" : "blocks",
			uid: authentication.currentUser.value?.uid,
			created: new Date().toISOString(),
			updated: new Date().toISOString(),
			extensions: activeExtensions.value.length > 0 ? activeExtensions.value : null,
			isAssignmentStarterProject: null,
			assignment: null
		};

		await firebase.default.firestore().collection("users").doc(authentication.currentUser.value?.uid).collection("projects").add(project);
	}
}

export async function saveFileToFirebaseAsync(): Promise<void> {
	if (authentication.currentUser.value) {
		const ref: firebase.default.storage.Reference = firebase.default.storage().ref(`blocks/${authentication.currentUser.value.uid}/${state.filename?.trim()} (${state.platform?.key})${state.textOnlyMode ? state.platform?.fileExtension : ""}`);
		
		state.currentProject = ref;

		if (!state.isFirebaseProject) {
			createV5Project();
		}

		state.isFirebaseProject = true;

		if (state.textOnlyMode) {
			const text: string = code.value;
			await ref.putString(text);
		}
		else {
			const code: string = xmlCode.value.replace("</xml>", `\n${JSON.stringify(activeExtensions.value)}`) + `\n</xml>`;
			await ref.putString(code);
		}
	}
	else {
		saveLocalFile();
	}
}

export function saveLocalFile(): void {
	const code: string = xmlCode.value.replace("</xml>", JSON.stringify(activeExtensions.value)) + `\n</xml>`;
	const blob: Blob = new Blob([code], {type: "text/xml;charset=utf-8"});
	let file: string = "untitled.xml";
	if (state.filename) {
		file = `${state.filename.trimEnd()}.xml`;
	}
	saveAs(blob, file);
}

export function saveCodeFile(): void {
	const blob: Blob = new Blob([code.value], {type: "text/text;charset=utf-8"});
	let file: string = `untitled.${state.platform?.fileExtension}`;
	if (state.filename) {
		file = `${state.filename.trimEnd()}.${state.platform?.fileExtension}`;
	}
	saveAs(blob, file);
}

export async function openLocalFile(): Promise<string> {
	return new Promise<string>((resolve: any, reject: any) => {
		function readSingleFile(e: Event): void {
		  const file: Blob = (e.target as any).files[0];
		  const name: string = (e.target as any).files[0].name;
		  if (!file) {
				return;
			}
	
		  const reader: FileReader = new FileReader();
	
		  reader.onload = (e: ProgressEvent<FileReader>): void => {
				const contents: string = (e.target as any).result;
				resolve(contents);
				state.filename = name.replace(".xml", "");
		  };
	
		  reader.onerror = (): void => {
				reject(new Error("Reader error"));
		  };
	
		  reader.readAsText(file);
		}
	
		const fileInput: HTMLInputElement = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = ".xml, .py, .html";
		fileInput.addEventListener("change", readSingleFile, false);
		fileInput.click();
	  });
}