import { authentication } from "@/data/providers/authentication-provider";

export class HeapUtilities {
	public static getUUID(): string {
		let uuid: string;
        
		if (localStorage.getItem("uuid")) {
			uuid = localStorage.getItem("uuid") as string;
		}
		else {
			uuid = crypto.randomUUID();
			localStorage.setItem("uuid", uuid);
		}

		return uuid;
	}

	public static async registerUser(): Promise<void> {
		const registerUser: firebase.default.functions.HttpsCallable = authentication.functions.httpsCallable("registerUser");
		await registerUser({ identity: this.getUUID(), type: authentication.currentUser.value ? "registered" : "anonymous" });
	}
}