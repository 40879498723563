import { dragElement, GuideModel, Guides, state } from "@/data/providers/global-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { circuitpython } from "@/platforms/circuitpython";
import { html } from "@/platforms/html";
import { microbit } from "@/platforms/microbit";
import { python } from "@/platforms/python";
import { raspberrypi } from "@/platforms/raspberrypi";
import router from "@/router";
import { reactive, ComputedRef, computed } from "vue";
import { GuidesState } from "./guides-state";

export class GuidesModel {
	// State for Guides View
	public state: GuidesState = reactive(new GuidesState());
		
	/**
	 * Get Translated text for the guides view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("guides", key);
	}

	public getAllGuides(): void {
		fetch(`https://api.storyblok.com/v2/cdn/stories?cv=1678320039&starts_with=guides&token=${process.env.VUE_APP_STORYBLOK_TOKEN as string}`).then(async (response: Response) => {
			this.state.guides = await response.json() as Guides;
		});
	}

	public getPlatformTitle(platform: string | undefined): string {
		let title: string = "";
		switch (platform) {
			case "python":
				title = python.name;
				break;
			case "microbit":
				title = microbit.name;
				break;
			case "raspberry-pi":
				title = raspberrypi.name;
				break;
			case "circuitpython":
				title = circuitpython.name;
				break;
			case "html":
				title = html.name;
				break;
		}
		return title;
	}

	public openGuide(): void {
		if (this.state.selectedGuide) {
			switch (this.state.selectedGuide.content.mode) {
				case "python":
					state.platform = python;
					break;
				case "microbit":
					state.platform = microbit;
					break;
				case "raspberry-pi":
					state.platform = raspberrypi;
					break;
				case "circuitpython":
					state.platform = circuitpython;
					break;
				case "html":
					state.platform = html;
					break;
			}
			router.push({path: "/editor"}).then(() => {
				dragElement(document.getElementById("guideWindow") as HTMLElement);
			});
			state.currentGuide = this.state.selectedGuide;
			this.state.selectedGuide = undefined;
		}
	}

	public goToLesson(url: string): void {
		location.href = url;
	}

	public guideResults: ComputedRef<Array<GuideModel> | undefined> = computed(() => {
		return this.state.guides?.stories.filter((guide: GuideModel) => {
			return this.state.platform === "all" || this.state.platform === this.getPlatformTitle(guide.content.mode) ? guide : undefined;
		});
	})

	public openDefaultGuide(): void {
		this.state.selectedGuide = this.state.guides?.stories[0];
		this.openGuide();
	}
}