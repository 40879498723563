
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { View } from "@/views/constants";
import { HomeModel } from "./home-model";
import { getLocalDataForFile } from "@/data/providers/files-provider";
import { getShowcaseProjectPlatform } from "@/data/providers/showcase-provider";
import { formatDate } from "@/data/providers/global-provider";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Home,
	setup() {
		const view: HomeModel = new HomeModel();

		onMounted(() => {
			view.getAllFilesFromFirebase();
			view.getLatestShowcaseProjects();
		});

		return { view, platforms, getLocalDataForFile, getShowcaseProjectPlatform, formatDate, authentication };
	}
});
