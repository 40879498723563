
import { defineComponent, onMounted } from "vue";
import { ShowcaseProjectsModel } from "./showcase-projects-model";
import { View } from "../../constants";
import { getShowcaseProjectPlatform } from "@/data/providers/showcase-provider";

export default defineComponent({
	name: View.ShowcaseProjects,
	setup() {
		const view: ShowcaseProjectsModel = new ShowcaseProjectsModel();

		onMounted(() => {
			view.init();
		});

		return { view, getShowcaseProjectPlatform };
	}
});
