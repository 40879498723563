<template>
	<div class="flex-none w-24 h-screen overflow-y-auto bg-white border-r border-gray-200">
		<div class="flex flex-col items-center w-full h-full py-6">
			<div class="flex items-center flex-shrink-0">
				<img
					class="w-auto h-9"
					src="/legacy/assets/images/small-logo.svg"
					alt="Workflow"
				>
			</div>
			<div class="flex-1 w-full px-2 mt-6 space-y-1">
				<a
					v-for="option in options"
					:key="option"
					class="flex flex-col items-center w-full p-3 text-xs font-medium text-center transition-all rounded-md"
					:class="option.key === this.$route.meta.key ? 'text-blue-500' : 'text-gray-400 cursor-pointer hover:text-gray-500'"
					@click="component.onItemClick(option)"
				>
					<component
						:is="option.icon"
						class="w-6 h-6"
					/>
					<span class="mt-2">{{ component.getText(option.key) }}</span>
				</a>
			</div>
			<div class="z-30 mt-auto">
				<div
					v-click-outside="component.closeDropdown"
					class="flex items-center space-x-2 cursor-pointer group"
					@click="isLanguageSelectorOpen = !isLanguageSelectorOpen"
				>
					<eb-flag
						:code="getCurrentLanguageIcon()"
						size="M"
						gradient="real-linear"
					/>
					<i class="text-sm text-gray-400 transition-all fas fa-sort group-hover:text-blue-500" />
				</div>

				<transition
					enter-active-class="transition duration-200 ease-out"
					enter-from-class="transform scale-95 opacity-0"
					enter-to-class="transform scale-100 opacity-100"
					leave-active-class="transition duration-75 ease-in"
					leave-from-class="transform scale-100 opacity-100"
					leave-to-class="transform scale-95 opacity-0"
				>
					<div
						v-if="isLanguageSelectorOpen"
						class="absolute z-50 w-48 py-1 mb-12 origin-bottom-left bg-white border rounded-md shadow-lg bottom-4 ring-1 ring-black ring-opacity-5 dark:bg-gray-800 dark:border-gray-700 dark:divide-gray-700 dark:backdrop-filter dark:backdrop-blur dark:bg-opacity-80"
					>
						<div class="px-4 py-3">
							<p class="text-sm dark:text-white">
								{{ component.getText('language') }}
							</p>
						</div>
						<button
							v-for="language in languages"
							:key="language"
							:class="state.language === language.code ? 'text-blue-500 font-medium' : 'text-gray-700 hover:text-gray-900'"
							class="flex justify-between w-full px-4 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-300 dark:hover:bg-white dark:hover:bg-opacity-20 dark:hover:text-white"
							@click="component.setLanguage(language.code)"
						>
							<div class="flex items-center space-x-4">
								<eb-flag
									:code="language.icon"
									size="S"
									class="h-3"
									gradient="real-linear"
								/>
								<h1>{{ component.getText(language.name) }}</h1>
							</div>
							<CheckIcon
								v-if="state.language === language.code"
								class="h-4"
							/>
						</button>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { NavigationItem } from "@/data/providers/navigation-provider";
import { EbNav, isLanguageSelectorOpen } from "./eb-nav";
import { languages, getCurrentLanguageIcon } from "@/data/providers/localization-provider";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbNav,
	props: {
		options: {
			type: Object as () => NavigationItem
		}
	},
	setup() {
		const component: EbNav = new EbNav();

		return { component, languages, isLanguageSelectorOpen, getCurrentLanguageIcon, state };
	}
});
</script>
