<template>
	<div class="space-y-8">
		<div
			class="relative w-full overflow-hidden bg-white bg-center bg-no-repeat bg-cover rounded-lg shadow-xl h-96"
			style="background-image: url('/legacy/assets/images/general/guides-header.png')"
		>
			<div class="absolute flex items-center w-full h-full p-10 bg-gradient-to-r from-gray-900">
				<div class="max-w-[30rem]">
					<h1 class="mb-2 text-4xl font-bold text-white">
						Build a website
					</h1>
					<p class="mb-6 text-xl font-medium text-gray-200">
						Ever wanted to build your own website using HTML? Why not try out the new HTML mode!
					</p>
					<EbButton
						text="Start"
						color="blue"
						@click="view.openDefaultGuide()"
					/>
				</div> 
			</div>
		</div>
		<EbPageHeader title="Guides">
			<div class="flex items-center space-x-4">
				<div class="w-64">
					<label class="block text-sm font-medium text-gray-700">
						Platform
					</label>
					<select
						v-model="view.state.platform"
						class="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
					>
						<option value="all">
							All
						</option>
						<option
							v-for="platform in platforms"
							:key="platform"
							:value="platform.name"
						>
							{{ platform.name }}
						</option>
					</select>
				</div>
			</div>
		</EbPageHeader>
		<div
			v-if="view.guideResults.value"
			class="grid grid-cols-2 pb-8 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
		>		
			<EbThumbnailCard
				v-for="guide in view.guideResults.value"
				:key="guide"
				:title="guide.content.title" 
				:subtitle="view.getPlatformTitle(guide.content.mode)"
				:image="guide.content.image.filename"
				@cardClick="view.state.selectedGuide = guide"
			/>
		</div> 
		<div
			v-else
			class="grid grid-cols-2 pb-8 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
		>
			<EbThumbnailCardSkeleton
				v-for="card in 8"
				:key="card"
			/>
		</div> 
	</div>
	<EbModal
		:visible="view.state.selectedGuide"
		size="sm:max-w-2xl sm:w-full"
	>
		<template #content>
			<div class="flex items-center p-4 bg-gray-50">
				<div>
					<h1 class="text-2xl font-semibold text-gray-900">
						{{ view.state.selectedGuide?.content.title }}
					</h1>
					<p class="text-xl font-semibold text-gray-500">
						{{ view.getPlatformTitle(view.state.selectedGuide?.content.mode) }}
					</p>
				</div>
				<img
					:src="view.state.selectedGuide?.content.image.filename"
					class="h-32 ml-auto"
				>
			</div>
			<div class="space-y-1">
				<h1 class="text-lg font-semibold text-gray-900">
					{{ view.getText('description') }}
				</h1>
				<h2 class="text-gray-500">
					{{ view.state.selectedGuide?.content.description }}
				</h2>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="lightBlue"
				@click="view.state.selectedGuide = undefined"
			/>
			<EbButton
				:text="view.getText('get-started')"
				color="blue"
				@click="view.openGuide()"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { GuidesModel } from "./guides-model";
import { View } from "../constants";
import { platforms } from "@/data/providers/platform-provider";

export default defineComponent({
	name: View.Guides,
	setup() {
		const view: GuidesModel = new GuidesModel();

		onMounted(() => {
			view.getAllGuides();
		});

		return { view, platforms };
	}
});
</script>