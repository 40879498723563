import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import router from "@/router";
import { reactive } from "@vue/reactivity";
import { ShowcaseProjectState } from "./showcase-project-state";
import { ShowcaseProject } from "./showcase-project-types";

export class ShowcaseProjectModel {
	// State for Projects View
	public state: ShowcaseProjectState = reactive(new ShowcaseProjectState());

	/**
	 * Get Translated text for the showcase project view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("showcase", key);
	}

	public init(): void {
		this.getProjectDetails();
	}

	public isUserAuthor(): void {
		this.state.isUserAuthor = authentication.currentUser.value?.uid === this.state.project?.uid;
	}

	public getURL(url: string): string {
		return location.host === "localhost:8080" ? `http://${location.host}/${url}` : `https://${location.host}/${url}`;
	}

	public goToUrl(url: string | undefined): void {
		location.href = this.getURL(url as string);
	}

	public deleteProject(): void {
		authentication.db.collection("showcase").doc(router.currentRoute.value.params.id as string).delete().then(() => {
			router.push({path: "/showcase"});
		});
	}

	public getProjectDetails(): void {
		authentication.db.collection("showcase").doc(router.currentRoute.value.params.id as string).get().then((snapshot: firebase.default.firestore.DocumentSnapshot) => {
			if (snapshot.data()) {
				this.state.project = snapshot.data() as ShowcaseProject;
				authentication.getUserDetails(this.state.project.uid).then((doc: object | undefined) => {
					if (doc) {
						this.state.user = doc;
						this.isUserAuthor();
					}
				});
			}
		});
	}
}