<template>
	<!-- Platform Selector -->
	<div
		v-if="!state.platform"
		class="flex flex-col w-screen h-screen"
	>
		<div class="flex flex-none w-full h-16 bg-white border-b border-gray-200">
			<div
				class="flex items-center justify-center h-full px-6 space-x-3 text-gray-500 transition-all border-r border-gray-200 cursor-pointer hover:text-blue-500"
				@click="this.$router.push({path: '/'})"
			>
				<i class="fas fa-chevron-left" />
				<span>{{ view.getText('back') }}</span>
			</div>
		</div>
		<div class="flex items-center justify-center w-full h-full px-6">
			<div class="w-full space-y-8 max-w-[90rem]">
				<div>
					<h1 class="text-5xl font-extrabold text-blue-500 sm:text-center">
						{{ view.getText('get-started') }}
					</h1>
					<p class="mt-5 text-xl text-gray-500 sm:text-center dark:text-gray-400">
						{{ view.getText('choose-a-platform') }}
					</p>
				</div>
				<EbRadioGroup
					v-model="view.state.selected"
					:options="platforms"
					name="platforms"
				/>
				<div class="w-32 mx-auto mt-5">
					<EbToggle
						title="Text Only"
						@toggled="state.textOnlyMode = true"
						@untoggled="state.textOnlyMode = false"
					/>
				</div>
			</div>
		</div>
		<div class="flex items-center flex-none w-full h-20 px-6 space-x-6 bg-white border-t">
			<EbInput
				v-model="state.filename"
				:label="view.getText('filename')"
				background="white"
				class="ml-auto w-80"
			/>
			<EbButton
				v-if="view.state.selected"
				:text="view.getText('start-coding')"
				color="blue"
				@click="view.setGlobalPlatform()"
			/>
		</div>
	</div>

	<!-- Editor -->
	<div
		v-if="state.platform && !state.showRaspberryPiModal"
		class="flex flex-col w-full h-full"
	>
		<EbToolbar>
			<template #buttonsLeft>
				<EbDropdown
					v-if="!state.textOnlyMode"
					:options="view.exportMenu"
					type="small"
				>
					<i
						v-tippy="{ content: 'Export Project' }"
						class="ml-3 text-gray-400 transition-all cursor-pointer fas fa-cloud-download-alt hover:text-gray-500"
					/>
				</EbDropdown>
			</template>
			<template #buttonsRight>
				<div class="flex items-center px-6 text-sm">
					<span
						v-if="view.state.savedMessage"
						class="text-green-400"
					>
						{{ view.getText('saved') }}
					</span>
					<span
						v-else
						class="text-gray-400"
					>
						{{ state.platform.name }}
					</span>
				</div>
				<div class="flex px-6 space-x-6">
					<EbButton
						color="outline"
						type="rounded"
						size="none"
						fa-icon="fas fa-book"
						class="flex-none"
						:tooltip="view.getText('sample-projects')"
						@click="this.$router.push({path: '/showcase'})"
					/>
					<EbDropdown
						v-if="authentication.currentUser.value && state.isFirebaseProject"
						:options="view.shareMenu"
						type="large"
						class="z-[40]"
					>
						<EbButton
							color="outline"
							type="rounded"
							size="none"
							class="flex-shrink-0"
							fa-icon="fas fa-share-alt"
							:tooltip="view.getText('share-project')"
						/>
					</EbDropdown>
				</div>
				<div
					v-if="state.platform.actions"
					class="px-6 space-x-6"
				>
					<EbButton
						v-for="button in state.platform.actions"
						:key="button"
						:visible="view.getEditorButton(button).visible"
						:text="view.getEditorButton(button).text"
						:color="view.getEditorButton(button).color"
						:icon="view.getEditorButton(button).icon"
						:fa-icon="view.getEditorButton(button).faIcon"
						:tooltip="view.getEditorButton(button).tooltip"
						@click="view.getEditorButton(button).action()"
					/>
				</div>
			</template>
		</EbToolbar>

		<div class="bg-white shadow-md h-[50.31px] px-8 flex flex-none w-full">
			<div class="flex">
				<div
					v-for="tab in view.editorTabs"
					v-show="tab.active !== EditorView.Code ? state.textOnlyMode === false : true"
					:key="tab"
					v-tippy="{ content: tab.active ? tab.tooltip : '' }"
					:class="view.state.view === tab.active ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2 mr-6"
					@click="tab.action"
				>
					{{ tab.title }}
				</div>
			</div>
			<div
				v-if="state.platform.name === 'HTML' && view.state.view === EditorView.Split"
				class="flex ml-auto space-x-6"
			>
				<div
					v-tippy="{ content: view.getText('switch-to-preview') }"
					:class="view.state.isHTMLPreviewActive ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2"
					@click="view.state.isHTMLPreviewActive = true; view.state.isCodeWindowActive = false;"
				>
					{{ view.getText('preview') }}
				</div>
				<div
					v-tippy="{ content: view.getText('switch-to-html') }"
					:class="view.state.isCodeWindowActive ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-transparent cursor-pointer hover:text-gray-500 hover:border-gray-500'"
					class="flex items-center h-full px-6 text-center transition-all border-b-2"
					@click="view.state.isCodeWindowActive = true; view.state.isHTMLPreviewActive = false;"
				>
					HTML
				</div>
			</div>
		</div>
		
		<div class="relative flex flex-col w-full h-full p-6 overflow-hidden">
			<div class="flex w-full h-full overflow-hidden">
				<div
					v-show="view.state.isBlockEditorActive"
					class="w-full h-full bg-white border dark:border-gray-700 dark:bg-gray-800"
				>
					<Blockly />
				</div>
				<div
					v-if="view.state.isCodeWindowActive"
					class="w-full h-full overflow-hidden bg-white border dark:border-gray-700"
					
					:class="view.state.view === EditorView.Code ? '' : 'max-w-xs xl:max-w-lg ml-4'"
				>
					<codemirror
						:value="code"
						:options="{
							mode: state.platform.name === 'HTML' ? 'htmlmixed' : 'python',
							lineNumbers: true,
							theme: 'edublocks',
							readOnly: false
						}"
						class="w-full h-full"
						@change="view.updateCode($event)"
					/>
				</div>
				<iframe
					v-if="view.state.isHTMLPreviewActive"
					class="w-full h-full max-w-xs ml-4 bg-white border xl:max-w-lg dark:border-gray-700"
					:srcdoc="code"
				/>
				<EbTrinket
					v-if="view.state.isTrinketWindowActive"
					class="w-full h-full max-w-md ml-4 bg-white border xl:max-w-lg"
				/>
				<div
					v-if="state.platform.key === 'RPi'"
					v-show="view.state.isRemoteTerminalWindowActive"
					id="terminal"
					class="w-full h-full max-w-md p-4 ml-4 bg-black bg-white border lg:max-w-lg"
				/>
				<div
					v-show="view.state.isSerialWindowActive"
					id="terminal"
					class="w-full h-full p-4 ml-4 bg-black bg-white border"
				/>
			</div>

			<div
				v-if="state.currentGuide"
				id="guideWindow"
				class="absolute top-4 right-4 z-[100] w-[35rem] bg-white rounded-lg shadow-2xl flex flex-col"
			>
				<div
					id="guideWindowheader"
					class="relative flex items-center justify-center flex-none w-full space-x-2 rounded-t-lg cursor-move h-14 bg-gradient-to-r from-blue-300 to-blue-600"
				>
					<h1 class="font-medium text-white">
						{{ state.currentGuide.content.title }}
					</h1>
					<i
						class="absolute text-white transition-all transform cursor-pointer left-3 top-5 fas fa-times hover:scale-110"
						@click="state.currentGuide = undefined"
					/>
				</div>
				<iframe
					v-if="state.currentGuide.content.video"
					:src="state.currentGuide.content.video"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
					allowfullscreen
					frameborder="0"
					class="w-full h-[20rem] rounded-b-lg"
				/> 
				<iframe
					v-else
					:src="`https://learn.edublocks.org/guide/${state.currentGuide.slug}`"
					frameborder="0"
					class="w-full h-[27rem] rounded-b-lg"
				/>
			</div>
		</div>

		<div
			v-if="state.platform?.extensionsActive"
			v-show="view.state.view !== EditorView.Code"
			id="addExtensionButton"
			class="absolute h-[68px] bg-white bottom-6 left-6 flex items-center justify-center px-4 border-l border-b border-gray-200"
		>
			<button
				v-tippy="{ content: 'Add Extension' }"
				class="w-full px-4 py-1.5 bg-blue-500 rounded-full hover:opacity-90 transition-opacity bg-gradient-to-b from-blue-300 to-blue-600 text-white ring-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
				@click="view.state.isExtensionModalOpen = true"
			>
				<PlusIcon class="h-8 text-white" />
			</button>
		</div>

		<EbModal
			:title="view.getText('publish-to-showcase')"
			:visible="view.state.isShowcasePublishModalOpen"
		>
			<template #content>
				<div class="space-y-4">
					<EbInput
						v-model="view.state.showcasePublishForm.title"
						:label="view.getText('title')"
						background="white"
					/>
					<EbInput
						v-model="view.state.showcasePublishForm.description"
						:label="view.getText('description')"
						background="white"
						textarea
					/>
				</div>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('close')"
					color="lightBlue"
					@click="view.state.isShowcasePublishModalOpen = false"
				/>
				<EbButton
					:text="view.getText('publish')"
					color="blue"
					@click="view.publishToShowcase()"
				/>
			</template>
		</EbModal>

		<EbModal
			title="Flashing code"
			:visible="view.state.isFlashing"
		>
			<template #content>
				<div class="flex items-center space-x-4">
					<div class="w-full h-3 rounded-full bg-blue-50">
						<div
							class="h-full bg-blue-500 rounded-full"
							:style="`width: ${view.state.flashProgress}%`"
						/>
					</div>
					<h1 class="text-gray-900">
						{{ view.state.flashProgress }}%
					</h1>
				</div>
			</template>
		</EbModal>

		<EbSlideout
			:visible="view.state.isExtensionModalOpen"
			title="Add Extension"
			subtitle="Extend the capabilites of EduBlocks"
			@exit="view.state.isExtensionModalOpen = false"
		>
			<template #content>
				<div class="flex flex-col h-full bg-gray-50">
					<div class="p-6 bg-white">
						<div class="text-sm mb-6">
							<h1 class="font-medium text-gray-900">
								Import extension from GitHub
							</h1>
							<p class="text-gray-500">
								Provide a link to a third-party extension hosted on GitHub
							</p>
						</div>
						<EbInput
							v-model="view.state.extensionURL"
							label="GitHub URL"
							background="white"
						/>
					</div>
					<div class="p-6 h-full">
						<div class="text-sm mb-6">
							<h1 class="font-medium text-gray-900">
								Choose an approved extension
							</h1>
							<p class="text-gray-500">
								Verified by EduBlocks
							</p>
						</div>
						<div class="grid gap-4 grid-cols-2 pb-6">
							<EbThumbnailCard
								v-for="extension in view.approvedExtensions"
								:key="extension"
								:title="extension.title"
								:subtitle="extension.subtitle"
								:image="extension.image"
								@click="view.loadExtension(extension.url)"
							/>
						</div>
					</div>
				</div>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('add')"
					color="blue"
					@click="view.loadExtension(view.state.extensionURL)"
				/>
			</template>
		</EbSlideout>

		<EbModal
			title="Code changes will be overwritten"
			:visible="view.state.isCodeEditWarningOpen"
		>
			<template #content>
				<p class="text-gray-500">
					Code changes will be overwritten once you edit the blocks or save this file. Create a text-only project if you want to type some code.
				</p>
			</template>
			<template #buttons>
				<EbButton
					:text="view.getText('close')"
					color="blue"
					@click="view.state.isCodeEditWarningOpen = false"
				/>
			</template>
		</EbModal>
	</div>

	<div
		v-if="state.showRaspberryPiModal"
		class="absolute z-50 flex flex-col w-screen h-screen"
	>
		<div class="flex flex-none w-full h-16 bg-white border-b border-gray-200">
			<div
				class="flex items-center justify-center h-full px-6 space-x-3 text-gray-500 transition-all border-r border-gray-200 cursor-pointer hover:text-blue-500"
				@click="this.$router.push({path: '/'})"
			>
				<i class="fas fa-chevron-left" />
				<span>{{ view.getText('back') }}</span>
			</div>
		</div>
		<div class="flex items-center justify-center w-full h-full px-6">
			<div class="text-center">
				<img
					src="/legacy/assets/images/platforms/pi-color.svg"
					class="h-40 mx-auto mb-5"
				>
				<h1 class="text-gray-800 font-bold text-2xl">
					Whoops! Can't connect to the server
				</h1>
				<p class="text-gray-500 text-lg mb-5">
					Make sure you're on a Raspberry Pi and have EduBlocks Link installed and running
				</p>
				<p class="text-gray-800 text-lg">
					Not installed EduBlocks Link? Run the following command
				</p>
				<div class="py-4 px-6 bg-gray-800 table mx-auto mt-2 text-white rounded-lg">
					curl -sSL get.edublocks.org | bash
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { platforms } from "@/data/providers/platform-provider";
import { authentication } from "@/data/providers/authentication-provider";
import { View } from "@/views/constants";
import { state } from "@/data/providers/global-provider";
import { EditorModel } from "./editor-model";
import { code } from "./editor-state";
import Blockly from "@/components/Blockly/Blockly.vue";
import { EditorView, EditorButtons } from "./editor-type";
import { NavigationGuardNext, onBeforeRouteLeave, RouteLocationNormalized } from "vue-router";
import { codemirror } from "vue-codemirror-lite";
import "codemirror/mode/python/python";
import "codemirror/mode/htmlmixed/htmlmixed";
import "@/assets/codemirror.css";

export default defineComponent({
	name: View.Editor,
	components: {
		Blockly,
		codemirror
	},
	setup() {
		const view: EditorModel = new EditorModel();

		onMounted(() => {
			view.checkForMode();
			if (state.textOnlyMode) {
				view.switchView(EditorView.Code);
			}
			else {
				view.switchView(EditorView.Split);
			}		
		});

		onBeforeRouteLeave((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
			if (!view.state.isSaved && state.platform && !state.showRaspberryPiModal) {
				if (confirm("Are you sure you want to leave without saving?")) {
					window.onbeforeunload = null;
					view.clear();
					state.assignmentModeActive = false;
					state.assignmentViewModeActive = false;
					state.isFirebaseProject = false;
					state.currentGuide = undefined;
					state.currentGuideStep = 0;
					next();
				}
			}
			else {
				view.clear();
				state.assignmentModeActive = false;
				state.assignmentViewModeActive = false;
				state.isFirebaseProject = false;
				state.currentGuide = undefined;
				state.currentGuideStep = 0;
				next();
			}
		});

		return { view, platforms, state, EditorView, code, EditorButtons, authentication };
	}
});
</script>
