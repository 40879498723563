<template>
	<div
		v-if="visible"
		class="relative m-1"
	>
		<div
			class="block overflow-hidden transition-all rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 group dark:focus-within:ring-offset-gray-900"
			:class="fixed ? 'aspect-w-10 h-[144px] w-60' : 'aspect-w-10 aspect-h-6 w-full'"
		>
			<img
				v-if="image"
				:src="image"
				class="object-cover transition-all pointer-events-none group-hover:opacity-75"
			>
			<div
				v-if="gradient"
				:class="gradient"
				class="flex items-center justify-center w-full h-full transition-all pointer-events-none group-hover:opacity-75"
			>
				<img
					v-if="gradientImage"
					:src="gradientImage"
					class="w-16 h-16 opacity-30"
				>
			</div>
			<button
				class="absolute inset-0 focus:outline-none"
				@click="$emit('cardClick')"
			/>
		</div>
		<div class="flex items-center justify-between">
			<div
				class="overflow-hidden"
				@click="$emit('cardClick')"
			>
				<p class="block mt-2 font-medium text-gray-900 truncate cursor-pointer dark:text-white w-60">
					{{ title }}
				</p>
				<div class="flex items-center space-x-2">
					<p class="block text-sm text-gray-500 cursor-pointer font-base dark:text-gray-400">
						{{ subtitle }}
					</p>
					<span
						v-if="badge"
						class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
					>
						{{ badge }}
					</span>
				</div>
			</div>

			<div
				v-if="dropdownOptions"
				v-click-outside="closeDropdown"
				class="relative flex items-center justify-end"
			>
				<DotsVerticalIcon
					class="w-5 h-5 text-gray-900 cursor-pointer dark:text-white"
					@click="isOpen = !isOpen"
				/>
				<transition
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform scale-95 opacity-0" 
					enter-to-class="transform scale-100 opacity-100"
					leave-active-class="transition duration-75 ease-in"
					leave-from-class="transform scale-100 opacity-100"
					leave-to-class="transform scale-95 opacity-0"
				>
					<div
						v-if="isOpen"
						class="absolute z-10 w-48 mx-3 mt-1 origin-top-right bg-white border divide-y divide-gray-200 rounded-md shadow-lg top-6 -right-3 ring-1 ring-black ring-opacity-5 dark:bg-gray-800 dark:border-gray-700 dark:divide-gray-700 dark:backdrop-filter dark:backdrop-blur dark:bg-opacity-80"
					>
						<div
							v-for="option in dropdownOptions"
							:key="option"
							class="py-1"
						>
							<a
								class="flex items-center px-4 py-2 text-sm text-gray-500 cursor-pointer group hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-white dark:hover:bg-opacity-20 dark:hover:text-white"
								@click="option.action"
							>
								<component
									:is="option.icon"
									class="w-5 h-5 mr-3 text-gray-600 group-hover:text-gray-500 dark:text-white dark:group-hover:text-gray-300"
								/>
								{{ option.title }}
							</a>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { DropdownOption } from "./eb-thumbnail-card-types";

export default defineComponent({
	name: ComponentName.EbThumbnailCard,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		image: {
			type: String
		},
		badge: {
			type: String
		},
		visible: {
			type: Boolean,
			default: true
		},
		gradientImage: {
			type: String
		},
		dropdownOptions: {
			type: Object as () => DropdownOption
		},
		gradient: {
			type: String
		},
		fixed: {
			type: Boolean,
			default: false
		},
	},
	emits: ["cardClick"],
	data() {
		return {
			isOpen: false
		};
	},
	methods: {
		closeDropdown() {
			this.isOpen = false;
		}
	}
});
</script>
