<template>
	<transition
		enter-active-class="transition duration-300 ease-out"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition duration-150 ease-in"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="visible"
			class="absolute w-full h-full bg-gray-300 opacity-70 inset-0 z-40"
			@click="$emit('exit')"
		/>
	</transition>

	<transition
		enter-active-class="transition duration-500 ease-in-out transform"
		enter-from-class="translate-x-full"
		enter-to-class="translate-x-0"
		leave-active-class="transition duration-500 ease-in-out transform"
		leave-from-class="translate-x-0"
		leave-to-class="translate-x-full"
	>
		<div
			v-if="visible"
			class="w-screen max-w-lg fixed right-0 z-50 h-screen"
		>
			<div class="h-full flex flex-col bg-white shadow-xl">
				<div class="py-6 px-4 bg-blue-500 sm:px-6">
					<div class="flex items-center justify-between">
						<h2
							id="slide-over-title"
							class="text-lg font-medium text-white"
						>
							{{ title }}
						</h2>
						<div class="ml-3 h-7 flex items-center">
							<button
								type="button"
								class="rounded-md text-blue-50 hover:text-white focus:outline-none"
								@click="$emit('exit')"
							>
								<svg
									class="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
					<div class="mt-1">
						<p class="text-sm text-white opacity-80">
							{{ subtitle }}
						</p>
					</div>
				</div>
				<div class="relative flex-1 overflow-y-auto bg-gray-50">
					<slot name="content" />
				</div>
				<div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-4 border-t border-gray-200">
					<slot name="buttons" />
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
 
export default defineComponent({
	name: ComponentName.EbSlideout,
	props: {
		title: {
			type: String
		},
		subtitle: {
			type: String
		},
		visible: {
			type: Boolean
		}
	},
	emits: ["exit"]
});
</script>