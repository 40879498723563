<template>
	<div class="flex flex-col w-full h-full">
		<div
			class="bg-red-500 rounded-lg mb-8"
		>
			<div class="py-3 px-3">
				<div class="flex items-center justify-between flex-wrap">
					<div class="w-0 flex-1 flex items-center">
						<span class="flex p-2 rounded-lg bg-red-700">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="h-6 w-6 text-white"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
								/>
							</svg>

						</span>
						<p class="ml-3 font-medium text-white">
							We're excited to introduce an all new Classrooms functionality! Please note that any classrooms/assignments created here will not be available in the new feature.
						</p>
					</div>
					<div class="ml-6 order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
						<a
							class="flex cursor-pointer items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-500 bg-white hover:bg-opacity-95"
							href="https://app.edublocks.org/classrooms"
						>
							Try it out!
						</a>
					</div>
				</div>
			</div>
		</div>

		<EbPageHeader
			:title="view.getText('classroom')"
			beta
		>
			<div
				v-if="authentication.currentUser.value"
				class="flex space-x-4"
			>
				<EbButton
					color="lightBlue"
					icon="UserAddIcon"
					:text="view.getText('join')"
					@click="view.state.isJoinModalOpen = true"
				/>
				<EbButton
					v-if="view.state.classrooms.length > 0"
					color="blue"
					icon="PlusIcon"
					:text="view.getText('create')"
					@click="view.state.isNewClassroomModalOpen = true"
				/>
			</div>
		</EbPageHeader>
		<div
			v-if="authentication.currentUser.value"
			class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
		>
			<EbThumbnailCardSkeleton
				v-for="card in 3"
				:key="card"
				:visible="view.state.isBusy"
			/>
			<EbThumbnailCard
				v-for="classroom in view.state.classrooms"
				:key="classroom"
				:title="classroom.data.name" 
				:subtitle="`${classroom.data.assignments.length} ${classroom.data.assignments.length === 1 ? view.getText('assignment') : view.getText('assignments')}`"
				gradient-image="/legacy/assets/images/general/classroom-icon.svg"
				:gradient="classroom.data.gradient"
				@click="this.$router.push({path: `/classroom/${classroom.id}`})"
			/>
		</div>

		<div
			v-if="!authentication.currentUser.value"
			class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
		>
			<img
				src="/legacy/assets/images/general/locked.svg"
				class="mb-6 h-44 justify-self-center"
			>
			<h1 class="mb-2 text-2xl font-medium text-gray-500">
				{{ view.getText('login') }}
			</h1>
			<h1 class="text-gray-400 text-md">
				{{ view.getText('login-description') }}
			</h1>
		</div>

		<div
			v-if="authentication.currentUser.value && !view.state.isBusy && view.state.classrooms.length === 0"
			class="flex flex-col items-center justify-center w-full h-full mx-auto text-center"
		>
			<img
				src="/legacy/assets/images/general/sad-window.svg"
				class="mb-6 h-36 justify-self-center"
			>
			<h1 class="mb-2 text-2xl font-medium text-gray-500">
				{{ view.getText('no-classrooms') }}
			</h1>
			<h1 class="text-gray-400 text-md">
				{{ view.getText('no-classrooms-description') }}
			</h1>
		</div>
	</div>
	
	<EbModal
		:title="view.getText('create-new-classroom')"
		:visible="view.state.isNewClassroomModalOpen"
	>
		<template #content>
			<div class="space-y-4">
				<EbInput
					v-model="view.newClassroomFormData.name"
					label="Name"
					background="white"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="lightBlue"
				@click="view.state.isNewClassroomModalOpen = false"
			/>
			<EbButton
				:text="view.getText('create')"
				color="blue"
				@click="view.createNewClass()"
			/>
		</template>
	</EbModal>

	<EbModal
		:title="view.getText('join-classroom')"
		:visible="view.state.isJoinModalOpen"
	>
		<template #content>
			<div class="space-y-6">
				<h1 class="-mt-4 text-sm text-gray-500 dark:text-gray-400">
					{{ view.getText('join-classroom-description') }}
				</h1>
				<EbInput
					v-model="view.joinClassroomFormData.code"
					label="Code"
					background="white"
				/>
			</div>
		</template>
		<template #buttons>
			<EbButton
				:text="view.getText('close')"
				color="lightBlue"
				@click="view.state.isJoinModalOpen = false"
			/>
			<EbButton
				:text="view.getText('join')"
				color="blue"
				@click="view.goToJoinLink()"
			/>
		</template>
	</EbModal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-model";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Classroom,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view, authentication };
	}
});
</script>
