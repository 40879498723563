import { App } from "vue";
import { ComponentName } from "./constants";

import EbNav from "./eb-nav/eb-nav.vue";
import EbHeader from "./eb-header/eb-header.vue";
import EbButton from "./eb-button/eb-button.vue";
import EbPageHeader from "./eb-page-header/eb-page-header.vue";
import EbCard from "./eb-card/eb-card.vue";
import EbThumbnailCard from "./eb-thumbnail-card/eb-thumbnail-card.vue";
import EbThumbnailCardSkeleton from "./eb-thumbnail-card/eb-thumbnail-card-skeleton.vue";
import EbRadioGroup from "./eb-radio-group/eb-radio-group.vue";
import EbToolbar from "./eb-toolbar/eb-toolbar.vue";
import EbTab from "./eb-tab/eb-tab.vue";
import EbInput from "./eb-input/eb-input.vue";
import EbTrinket from "./eb-trinket/eb-trinket.vue";
import EbUserMenu from "./eb-user-menu/eb-user-menu.vue";
import EbSelector from "./eb-selector/eb-selector.vue";
import EbModal from "./eb-modal/eb-modal.vue";
import EbSelect from "./eb-select/eb-select.vue";
import EbListItem from "./eb-list-item/eb-list-item.vue";
import EbToggle from "./eb-toggle/eb-toggle.vue";
import EbDropdown from "./eb-dropdown/eb-dropdown.vue";
import EbSearch from "./eb-search/eb-search.vue";
import EbFlag from "./eb-flag/eb-flag.vue";
import EbSlideOut from "./eb-slideout/eb-slideout.vue";

export default {
	install(app: App): void {
		app.component(ComponentName.EbNav, EbNav);
		app.component(ComponentName.EbHeader, EbHeader);
		app.component(ComponentName.EbButton, EbButton);
		app.component(ComponentName.EbPageHeader, EbPageHeader);
		app.component(ComponentName.EbCard, EbCard);
		app.component(ComponentName.EbThumbnailCard, EbThumbnailCard);
		app.component(ComponentName.EbRadioGroup, EbRadioGroup);
		app.component(ComponentName.EbToolbar, EbToolbar);
		app.component(ComponentName.EbTab, EbTab);
		app.component(ComponentName.EbInput, EbInput);
		app.component(ComponentName.EbTrinket, EbTrinket);
		app.component(ComponentName.EbUserMenu, EbUserMenu);
		app.component(ComponentName.EbThumbnailCardSkeleton, EbThumbnailCardSkeleton);
		app.component(ComponentName.EbSelector, EbSelector);
		app.component(ComponentName.EbModal, EbModal);
		app.component(ComponentName.EbSelect, EbSelect);
		app.component(ComponentName.EbListItem, EbListItem);
		app.component(ComponentName.EbToggle, EbToggle);
		app.component(ComponentName.EbDropdown, EbDropdown);
		app.component(ComponentName.EbSearch, EbSearch);
		app.component(ComponentName.EbFlag, EbFlag);
		app.component(ComponentName.EbSlideout, EbSlideOut);
	}
};